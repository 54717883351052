ngx-material-timepicker-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

ngx-material-timepicker-content {
  width: 100%;
  max-width: 350px;
}

.timepicker {
  width: 100% !important;

  &__actions {
    justify-content: center !important;

    .btn {
      margin: 5px;
    }
  }
}

ngx-material-timepicker-period {
  display: none !important;
}

.timepicker-dial__container {
  justify-content: center !important;
}
