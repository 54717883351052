@import "variables";

.mat-mdc-text-field-wrapper {
  background-color: var(--light);
  border-radius: 10px !important;

  .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 21px;
    font-size: 14px;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #cfd8dc;
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field-infix {
  padding: 0.5em 0 !important;
  border-top: 0 !important;
  margin-top: 0.3em;
  min-height: 40px;
  font-size: 14px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-28.75px) scale(var(--mat-mdc-form-field-floating-label-scale, .75));
  transform: var(--mat-mdc-form-field-label-transform);
  font-size: 14px;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 10px 0 0 10px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0 10px 10px 0 !important;
}

.mat-mdc-form-field-error {
  height: 100%;
  position: relative;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;

  .mat-mdc-button-touch-target {
    width: 40px;
    height: 40px;
  }

  svg {
    color: var(--gray);
  }
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 30px !important;
}
