.table-wrapper {
  min-width: 100%;
  max-width: calc(100vw - 45px);
  overflow: auto;

  @include md {
    max-width: calc(100vw - 120px);
  }

  @include xl {
    max-width: calc(100vw - 255px);
  }
}

table,
.table {
  width: 100%;

  .table-heading {
    background-color: $accent-background;

    th:first-child {
      border-top-left-radius: 6px;
    }

    th:last-child {
      border-top-right-radius: 6px;
    }

    th {
      border-bottom: none !important;
    }
  }

  .table-body {
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #1960bc;
    }

    &:focus {
      outline: none;
    }
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell {
    padding: 8px;
    font-size: 14px;
    text-align: left;
    font-weight: $regular-font-weight;
  }

  th,
  td {
    &.mat-column-date {
      min-width: 160px;

      span {
        margin-left: 6px;
      }
    }

    &.mat-column-specialization,
    &.mat-column-type {
      max-width: 165px;

      mat-form-field {
        max-width: 100%;
      }
    }

    &.mat-column-specialization {
      p {
        margin: 6px 0;
      }
    }

    &.mat-column-action {
      text-align: right;

      mat-icon.not-read-mail {
        color: $accent-color;
      }
    }

    &.mat-column-status.active {
      color: $accent-color !important;
    }

    .icon-double-arrow {
      margin-left: 10px !important;
    }
  }
}

.empty-table-result {
  padding: 20px;
  text-align: center;
  width: 100%;
}
