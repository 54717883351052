@import "breakpoints";

html,
body {
  margin: 0;
  background-color: var(--light);
  height: 100%;
  min-height: 100dvh;
  font-size: 14px;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: $regular-font-weight;
  color: var(--font-base);
  position: relative;
  line-height: 1.2;
  touch-action:none;

  * {
    box-sizing: border-box;
  }
}

.global_wrap {
  position: relative;
  z-index: 1;

  &_bg {
    position: fixed;
    inset: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    z-index: -1;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.page-container {
  padding: 15px 0;
  max-width: 1500px;
  margin: 0 auto;

  @include sm {
    padding: 20px 15px;
  }
}

.additional-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }

  .btn {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 0;
    }

    & + .btn {
      @include md {
        margin-left: 20px;
      }
    }

  }
}

hr {
  width: 100%;
  border: none;
  border-top: 1px solid var(--light);
}

.authorized .grecaptcha-badge {
  right: -300px !important;
}

.title {
  font-weight: 500;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-focus-overlay {
  background-color: transparent !important;
}

.title {
  font-size: 18px;
  display: flex;
  justify-content: center;

  @include sm {
    font-size: 24px;
  }
}

.card {
  background-color: var(--light);
  border: 1px solid var(--gray);
  border-radius: 12px;
}

.empty_avatar {
  background-color: var(--secondary);
  height: 100%;
  display: flex;
  justify-content: center;
}

.authorized .grecaptcha-badge {
  right: -300px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-focus-overlay {
  background-color: transparent !important;
}

.authorized .grecaptcha-badge {
  right: -300px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-focus-overlay {
  background-color: transparent !important;
}

app-validator-message {
  position: absolute;
  inset: 0;
}

.error {
  font-size: 12px;
}

.custom_error {
  font-size: 12px;
  position: absolute;
  width: 100%;
  height: fit-content;
  left: 16px;
  text-align: left;
  color: var(--mdc-theme-error, #f44336);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, .0333333333em);
}
