.mat-accordion.accordion-table {
  width: 100%;

  .mat-expansion-panel {
    border-radius: 0 !important;
    background-color: transparent;
    color: $light-color;
    box-shadow: none;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
  }

  .mat-expansion-panel-header {
    background-color: $accent-muted-color;
    padding: 0 26px 0 14px;
    min-height: 64px;
    height: auto;
    cursor: pointer;

    @include xl {
      padding: 0 36px 0 26px;
    }

    .mat-expansion-panel-header-title {
      font-weight: $medium-font-weight;
      font-size: 1.143rem;
      color: $light-color;
    }

    .mat-expansion-indicator:after {
      color: $light-color;
    }
  }

  .mat-expansion-panel-header[aria-disabled='true'] {
    cursor: default;
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']),
  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background-color: $accent-muted-color;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }
}
