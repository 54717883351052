.no-wrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-15 {
  margin-right: 15px;
}
