.alert-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background: var(--light);
  border-radius: $border-radius;
  margin-bottom: 24px;

  @include xl {
    flex-direction: row;
  }

  .text {
    flex-grow: 1;
  }

  .title {
    font-weight: $medium-font-weight;
    font-size: 1.286rem;
  }

  .message {
    font-weight: 400;
  }

  .title + .message {
    margin-top: 15px;
  }

  button {
    margin-top: 20px;

    @include xl {
      margin-left: 20px;
      margin-top: 0;
    }
  }
}
