
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-checked {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle {
    border-color: var(--primary-light) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-inner-circle {
    background-color: var(--primary-light) !important;
  }
}

.mat-mdc-radio-button {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle {
    border-color: #DADADA;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-radio-disabled {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle {
    border-color: $muted-disabled-color !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-inner-circle {
    background-color: $muted-disabled-color !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-label-content {
    color: $muted-disabled-color !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background: none !important;
}
