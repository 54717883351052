.calendar {
  padding-top: 20px;

  .cal-week-view {
    border-top: none;

    .cal-header b {
      text-transform: capitalize;
    }

    .cal-time-events {
      border: none !important;

      .cal-day-column {
        position: static !important;
      }

      .cal-event {
        width: calc(100% - 15px);
        height: 100%;
        min-height: 36px;
        padding: 0 5px;
        line-height: 36px;
        color: var(--font-base) !important;
      }
    }

    .cal-current-time-marker {
      left: 0;
      background: var(--secondary);
      width: 100%;
      margin-left: 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: 0;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 16px solid var(--secondary);
        border-bottom: 8px solid transparent;
      }
    }

    .cal-hour-odd {
      background-color: transparent;
    }

    .cal-hour .cal-hour-segment {
      border-top: 1px solid #eee !important;
      border-bottom: none !important;
    }

    .cal-hour:last-child .cal-hour-segment .cal-time:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 80%;
      background-color: #fff;
    }

    .cal-hour .cal-hour-segment.disable {
      background-color: #DADADA;

      &:hover {
        background-color: #DADADA;
      }
    }

    .cal-day-headers {
      border-left: none;
      border-right: none;

      .cal-header {
        border: none !important;
      }
    }

    .cal-time {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -30px;
      background-color: #fff;
      padding: 0;
    }

    .cal-header.cal-today {
      background-color: var(--primary-extra-light);
    }

  }

  .cal-month-view {
    .cal-day-cell {
      max-height: 100px;
      overflow: hidden;
    }

    .cal-day-cell.cal-today .cal-day-number,
    .cal-day-number {
      font-size: inherit !important;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--font-base);
    }

    .cal-header .cal-cell {
      text-transform: capitalize;
    }

    .cal-day-cell.cal-today {
      background-color: transparent;

      & .cal-day-number {
        opacity: 1;
        background-color: var(--primary-extra-light);
      }
    }
    .cal-day-cell.disable {
      filter: grayscale(100%);
      background-color: #DADADA;
    }

    .cal-day-number {
      float: none;
      margin: 5px;
    }

    .cal-cell-top {
      display: flex;
      flex-direction: column;
    }
  }

  .has_icon {
    mwl-calendar-event-title {
      position: relative;
    }

    .cal-event-title {
      margin-left: 25px;
      position: relative;

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -20px;
        top: 2px;
      }
    }
  }

  .icon_online {
    .cal-event-title {
      &:before {
        background-image: url(/assets/images/online.svg);
      }
    }
  }

  .icon_offline {
    .cal-event-title {
      &:before {
        background-image: url(/assets/images/offline.svg);
        top: -1px;
      }
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: var(--font-base);
  font-weight: bold;
}
