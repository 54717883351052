@import "breakpoints";

.ng-select-custom {
  position: relative;

  .mat-mdc-form-field-error {
    transition: .3s;
    position: absolute;
    bottom: 0;
    left: 0;
    height: auto;
    font-size: 12px;
    padding-left: 5px;
  }
}

.ng-select:not(.language) {
  caret-color: var(--font-base);

  .ng-select-container {
    background-color: var(--light);
    padding-left: 11px;
    padding-right: 11px;
    border-radius: 10px;
    border: 1px solid #DCE3E9;
    transition: .3s;
    min-height: 48px;
    padding-top: 1px;
    color: var(--font-base) !important;
    cursor: pointer !important;
    overflow: inherit !important;

    &:after {
      border-bottom: 0;
    }

    .ng-arrow-wrapper {
      bottom: 16px !important;
    }

    .ng-arrow {
      color: #868FAF;
      transition: .2s;
    }

    .ng-input {
      left: 14px !important;
      width: auto !important;
      right: 22px !important;
      bottom: 0.7em !important;
    }

    .ng-placeholder {
      color: var(--font-base) !important;
      line-height: 1.4;
      top: 8px !important;
      background-color: var(--light);
      padding: 2px 5px;
    }
  }


  .ng-value-container,
  input {
    cursor: pointer !important;
    color: var(--font-base) !important;
  }

  .ng-placeholder {
    color: var(--font-base) !important;
  }

  .ng-arrow {
    color: var(--font-base) !important;
  }

  &.ng-touched.ng-invalid {
    .ng-select-container {
      border-color: var(--warn-color) !important;
    }

    .ng-placeholder {
      color: var(--warn-color) !important;
    }
  }

  &.ng-dirty {
    .ng-placeholder {
      color: var(--font-base) !important;
    }
  }

  &.ng-select-focused,
  &.ng-dirty,
  .ng-has-value {
    .ng-placeholder {
      color: var(--font-base) !important;
    }
  }

  &.ng-select-disabled {
    .ng-placeholder {
      color: var(--font-base) !important;
    }

    .ng-value {
      color: var(--font-base) !important;
    }
  }
}

.dialog-content {
  .ng-select .ng-placeholder {
    color: var(--font-base) !important;
  }
}

.select-lang {
  font-weight: 500;

  .ng-select .ng-select-container:after {
    border: none;
  }
}

.language {
  &.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: center !important;
    bottom: 2px !important;
  }

  .ng-select-container .ng-value-container {
    justify-content: center;
  }
}
