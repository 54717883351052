.mat-datepicker-toggle {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    color: var(--font-base);
  }
}

.mat-date-range-input {
  .mat-date-range-input-separator {
    color: var(--font-base);
  }
}

.mat-calendar {
  table tbody tr {
    &:hover {
      background-color: transparent;
    }
  }

  .mat-calendar-controls {
    margin: 6px 0;
  }
}

.mat-calendar-body-selected {
  background-color: var(--primary);
}

.timepicker__header {
  background-color: var(--primary) !important;
}

.clock-face__number > span.active,
.clock-face__clock-hand {
  background-color: var(--primary) !important;
}
