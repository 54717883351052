.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
  overflow: hidden;
  background: var(--secondary-light);

  &.avatar-exist {
    background: none;
  }

  app-safe-image {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.miniature-of-documents {
  app-safe-image {
    height: 100%;
    width: 100%;
  }

  img:not(.icon) {
    width: 100%;
    height: 100%;
    max-height: 100% !important;
    object-fit: cover;
  }
}
