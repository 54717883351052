@import 'breakpoints';

.files-menu-backdrop {
  + .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-menu-panel {
      border-radius: 0;
    }

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.schedule-slot-menu-backdrop,
.notifications-menu-backdrop {
  + .cdk-overlay-connected-position-bounding-box {

    .cdk-overlay-pane {
      top: initial !important;
      left: initial !important;
      right: initial !important;
    }

    .mat-mdc-menu-panel {
      max-width: none !important;
      border-radius: 0;
      background-color: transparent;
    }

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.mat-mdc-fab:hover,
.mat-mdc-fab:focus,
.mat-mdc-mini-fab:hover,
.mat-mdc-mini-fab:focus {
  box-shadow: none !important;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}
