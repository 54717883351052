.rounded-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-extra-light);
  height: 26px;
  width: 26px;
  border-radius: 50%;
  overflow: hidden;
  color: var(--font-base);
  font-weight: $medium-font-weight;
  font-size: 0.857rem;
}
