.mat-badge .mat-badge-content {
  font-size: 9px;
  background-color: var(--secondary);
  color: var(--font-base);
}

.mat-mdc-tooltip {
  font-size: 12px;
  color: var(--lihgt);
  background-color: #37474F;
  border-radius: 4px;
}

.dialogs-link {
  .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: 0 !important;
  }
}
