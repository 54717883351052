button {
  font-family: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: var(--font-base);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  box-shadow: none;
  border: none;
  transition: all 0.3s;
  border-radius: 100px;
  min-height: 40px;

  &:hover {
    text-decoration: none;
    transition: all 0.3s;
  }

  &:disabled {
    opacity: .6;
  }

  &.btn-round {
    padding: 10px;
    border-radius: 50%;
    border: 1px solid var(--gray);
    cursor: pointer;
    max-width: 48px;
    background-color: var(--light);

    &:hover {
      background-color: var(--primary);
      border-color: var(--primary);
      transition: .3s;
    }
  }
}

.btn-primary {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: var(--font-secondary);

  &:hover,
  &:active {
    background: var(--light);
    color: var(--font-base);
  }
}

.btn-secondary {
  background: var(--light);
  border: 1px solid var(--primary);
  color: var(--font-base);

  &:hover,
  &:active {
    background: var(--primary);
    color: var(--font-secondary);
  }

  &_bordered {
    &:hover,
    &:active {
      border: 1px solid var(--light);
    }
  }
}

.ngx-material-timepicker-toggle {
  position: absolute;
  right: 2px;
  top: 5px;

  svg {
    fill: var(--gray);
  }
}
