.chat-error {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 10px;

  .message {
    font-weight: bold;
    font-size: 1.857rem;
  }

  .btn-to-home {
    margin: 30px 0;
  }
}
