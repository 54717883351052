@import 'variables';

.events-list {
  display: block;
  background: var(--light);
  border-radius: 6px;
  height: 100%;

  .events-list-layout {
    padding: 15px;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .events {
    display: flex;
  }

  .title {
    font-weight: $medium-font-weight;
    font-size: 1.286rem;
  }

  .event {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;

    .item {
      display: flex;
      width: 25%;
      align-items: center;
      justify-content: center;

      &_wrap {
        width: 55px;
        padding: 5px 10px;
        border: 1px solid #DCE3E9;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        margin-bottom: 10px;
      }

      &.selected {

        .item_wrap {
          background-color: var(--primary-extra-light);
          border-color: var(--primary-extra-light);
        }
      }

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }

      &:hover {
        .item_wrap {
          background-color: var(--primary-extra-light) !important;
          opacity: 0.8;
        }
      }
    }

    &_modal {
      max-width: inherit;

      .item {
        width: 25%;

        @include md {
          width: calc(100% / 6);
        }
      }
    }
  }
}
