.mat-mdc-checkbox .mdc-checkbox {
  height: 16px;

  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: #cfd8dc !important;
  }

  .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
    border-color: var(--light) !important;
  }

  .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
    opacity: 0;
  }

  .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple,
  .mat-ripple .mat-mdc-checkbox-ripple .mat-mdc-focus-indicator {
    background-color: transparent !important;
    opacity: 0 !important;
  }
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #fff;
}


.terms-of-use-checkbox {
  label {
    white-space: normal;
    text-align: left;
    font-size: 12px;
    line-height: 16px;

    a {
      color: #90A4AE;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-frame {
    border-color: #78909C !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: var(--primary) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background: transparent !important;
  }
}
