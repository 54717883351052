.cdk-overlay-container {
  .mat-mdc-snack-bar-container {
    color: var(--font-base);
    background: var(--primary);

    .mat-mdc-button {
      color: var(--font-base);
    }
  }
}
