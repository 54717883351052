.mat-accordion.accordion-plain {
  .mat-expansion-panel {
    background-color: transparent;
    color: $text-color;
    margin: 12px 0;
    border-radius: $border-radius !important;
    box-shadow: none;
  }

  .mat-expansion-panel + .mat-expansion-panel {
    margin-top: 24px;
  }

  .mat-expansion-panel-header {
    background-color: $sidebar-color;
    padding: 16px 18px;
    min-height: 56px;
    height: auto;
    cursor: pointer;

    .mat-expansion-panel-header-title {
      font-weight: $medium-font-weight;
      font-size: 1.143rem;
      color: $text-color;
    }

    .mat-expansion-indicator:after {
      color: $text-color;
    }
  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']),
  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background: $primary-color;
  }

  .mat-expansion-panel-body {
    padding: 40px 20px 0;
    background-color: #fff;
  }
}
