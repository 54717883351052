.invite-form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: var(--primary-hover-light);
  border: 1px solid #DCE3E9;
  border-radius: 12px;
  margin-top: 50px;
  padding: 16px 24px;

  @include md {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .text {
    color: var(--font-base);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0;

    @include md {
      margin-right: 16px;
    }

    @include xl {
      text-align: left;
    }
  }

  .invite-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-width: 215px;

    @include md {
      align-items: center;
      flex-direction: row;
    }
  }

  mat-form-field {
    width: 100%;
    min-width: 170px;
    margin-top: 13px;

    @include md {
      margin-right: 24px;
    }

    @include xl {
      margin-bottom: 0;
    }

    @include sm {
      min-width: 280px;
    }
  }

  .btn {
    height: 40px;
  }
}
