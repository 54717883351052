.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  fill: #fafafa !important;
}

.mdc-switch--selected .mdc-switch__icon--on, .mdc-switch--unselected .mdc-switch__icon--off {
  opacity: 0 !important;
}

.mat-mdc-slide-toggle {
  --mdc-switch-unselected-focus-state-layer-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: transparent;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-handle-color: #fafafa;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #fafafa !important;
  --mdc-switch-selected-handle-color: #fafafa !important;
  --mdc-switch-selected-hover-state-layer-color: transparent !important;
  --mdc-switch-selected-pressed-state-layer-color: #757575 !important;
  --mdc-switch-selected-focus-handle-color: #fafafa !important;
  --mdc-switch-selected-hover-handle-color: #fafafa !important;
  --mdc-switch-selected-pressed-handle-color: #fafafa !important;
  --mdc-switch-selected-focus-track-color: var(--primary) !important;
  --mdc-switch-selected-hover-track-color: var(--primary) !important;
  --mdc-switch-selected-pressed-track-color: var(--primary) !important;
  --mdc-switch-selected-track-color: var(--primary) !important;
}
