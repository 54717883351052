@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?b0hhvd');
  src:  url('fonts/icomoon.eot?b0hhvd#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?b0hhvd') format('truetype'),
    url('fonts/icomoon.woff?b0hhvd') format('woff'),
    url('fonts/icomoon.svg?b0hhvd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-double-arrow:before {
  content: "\e900";
}
.icon-data-template:before {
  content: "\e922";
}
.icon-education:before {
  content: "\e923";
}
.icon-photo:before {
  content: "\e924";
}
.icon-home:before {
  content: "\e925";
}
.icon-logout:before {
  content: "\e926";
}
.icon-help:before {
  content: "\e927";
}
.icon-profile:before {
  content: "\e928";
}
.icon-files:before {
  content: "\e929";
}
.icon-schedule:before {
  content: "\e92a";
}
.icon-patients:before {
  content: "\e92b";
}
.icon-consult:before {
  content: "\e92c";
}
.icon-add-file:before {
  content: "\e92d";
}
.icon-clock-progress:before {
  content: "\e92e";
}
.icon-message:before {
  content: "\e92f";
}
.icon-notification-new-dialogue:before {
  content: "\e930";
}
.icon-notification-analyzes-status:before {
  content: "\e931";
}
.icon-notification-recommendation-status:before {
  content: "\e932";
}
.icon-search:before {
  content: "\e933";
}
.icon-notification-cancel-consultation:before {
  content: "\e934";
}
.icon-notification-connect-chat:before {
  content: "\e935";
}
.icon-notification-connect-wr:before {
  content: "\e936";
}
.icon-notification-left-chat:before {
  content: "\e937";
}
.icon-notification-left-wr:before {
  content: "\e938";
}
.icon-clinic:before {
  content: "\e939";
}
.icon-end-call:before {
  content: "\e93a";
}
.icon-start-call:before {
  content: "\e93b";
}
.icon-video-off:before {
  content: "\e93c";
}
.icon-video-on:before {
  content: "\e93d";
}
.icon-voice-off:before {
  content: "\e93e";
}
.icon-voice-on:before {
  content: "\e93f";
}
.icon-notification-consultation-crated:before {
  content: "\e940";
}
.icon-telegram:before {
  content: "\ea95";
}
