mat-sidenav-container.mat-sidenav-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: initial;
  background-color: transparent;
  color: inherit;

  .mat-sidenav-content {
    display: flex;
    flex-direction: column;
    z-index: initial;
  }
}

.sidenav {
  .wrapper-nav {
    height: 40%;

    & a {
      display: inline-flex;
      font-weight: 500;
      padding: 10px 20px;
      border-radius: 25px;
      background-color: transparent;
      transition: .3s;
      text-decoration: none;
      position: relative;
    }

    & a:hover,
    & a.active {
      background-color: var(--primary);
      transition: .3s;
      text-decoration: none;
    }
  }

  .wrapper-info {
    height: 40%;
    padding: 0 10px;

    & a {
      display: inline-flex;
      font-weight: 500;
    }
  }

  .apps {
    padding-bottom: 10px;

    & a {
      display: inline-flex;
      max-width: fit-content !important;
    }

    & a > img {
      max-width: 80px;
      height: 80px;
    }
  }
}
