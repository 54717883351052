@import 'breakpoints';

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 12px !important;
}

.primary-modal {
  width: 100%;
  border-radius: 0;
  position: relative;
  overflow-y: auto;
  max-height: 90% !important;

  @include md {
    max-width: 450px !important;
  }

  &.modal-sm {
    @include md {
      max-width: 550px !important;
    }
  }

  &.modal-md {
    @include md {
      max-width: 760px !important;
    }
  }

  &.modal-xl {
    @include md {
      max-width: 900px !important;
    }
  }

  .mdc-dialog__container {
    box-shadow: none;
    border-radius: 12px;
    padding: 0;
    background-color: var(--light);
  }

  .dialog-content {
    padding: 10px 18px;
    color: var(--font-base);
    flex-wrap: nowrap;

    p {
      margin: 0;
    }
  }

  .mat-mdc-dialog-actions {
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 0;

    button {
      margin: 8px;
    }
  }
}

.modal-deactivate-profile {
  .mat-mdc-dialog-actions {
    flex-direction: column;

    .btn {
      max-width: none;
    }
  }
}

.modal-upload-file {
  .mat-mdc-dialog-actions {
    flex-wrap: wrap;
  }
}

.cdk-overlay-container {
  position: fixed;
}

.cdk-global-scrollblock {
  position: static !important;
  overflow: hidden !important;
}

.modal_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  overflow: hidden;

  .modal-img-state {
    height: 50%;
  }
}

.modal_content {
  background-color: var(--light);
  border-radius: 12px;
}

.modal_image {
  padding-top: 15px;
  display: flex;
  justify-content: center;

  @include md {
    padding-top: 25px;
  }
}

.cdk-overlay-pane {
  max-width: 90vw !important;

  @include sm {
    max-width: 80vw !important;
  }
}
