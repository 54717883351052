@mixin xs {
  @media screen and (min-width: 380px) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

// Tablets and small desktops
@mixin xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

// Large tablets and desktops
@mixin xxl {
  @media screen and (min-width: 1440px) {
    @content;
  }
}
