.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--light);
  border-radius: $border-radius;
  border: 1px solid #DCE3E9;

  &-gray {
    background: #E5E5E5;
  }

  &.linked {
    cursor: pointer;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex-grow: 1;
    color: var(--font-base);

    @include sm {
      flex-direction: row;
    }
  }

  .user {
    display: flex;
    align-items: flex-start;
    margin-bottom: 14px;
    font-size: 16px;
    width: 100%;

    @include sm {
      align-items: center;
      margin-bottom: 0;
    }

    .avatar {
      margin-right: 8px;
    }

    .patient_info {
      display: flex;
      flex-direction: row;
      align-items: initial;
      height: 100%;
      width: 100%;
      justify-content: space-between;

      @include sm {
        flex-direction: column;
      }
    }

    .name {
      &_full {
        font-weight: $medium-font-weight;
        word-break: break-word;
      }

      .username {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }

  .history-link {
    text-decoration-line: underline;
    color: var(--primary-light);
    cursor: pointer;
  }

  .last-consultation {
    font-weight: 500;
    margin-bottom: 20px;
    color: var(--gray);

    @include sm {
      margin-bottom: initial;
    }
  }

  .duration-icon {
    font-size: inherit;
  }

  .duration-time {
    font-weight: bold;
  }

  .anamnesis-text {
    margin-bottom: 20px;

    .title {
      font-weight: $medium-font-weight;
    }
  }

  app-specialization-names {
    margin-bottom: 20px;
  }

  .rating {
    margin-bottom: 20px;

    mat-icon {
      color: #636ca6;

      &.active {
        color: #f2c94c;
      }
    }
  }

  .cost,
  .duration {
    display: flex;
    align-items: center;

    .icon,
    mat-icon {
      font-size: 24px;
      margin-right: 10px;
    }

    .text {
      color: $text-color;
      display: inline-block;
      margin-right: 5px;
    }

    .value {
      color: $text-color !important;
      font-weight: 500;
    }
  }

  .duration {
    margin-bottom: 20px;
  }

  .actions {
    margin-top: auto;
    margin-bottom: 0;

    button,
    .consultation-status {
      width: 100%;
      max-width: 100%;
    }

    app-invite-by-type-controls {
      button {
        margin-top: 0 !important;
      }
    }
  }

  .linked-action {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    text-decoration: none;

    mat-icon {
      margin-right: 8px;
      color: $text-color;
    }

    span {
      text-decoration: underline;
      font-weight: $medium-font-weight;
      font-size: 1.143rem;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.consultation-card {
  app-communication-type {
    .icon {
      font-size: 20px;
      color: $text-color !important;
    }
  }
}

.patient-card {
  .heading {
    span {
      color: $primary-color;
    }
  }
}

.consultation-history {
  display: block;
  margin-bottom: 20px;
  font-weight: $medium-font-weight;
  text-decoration: none;
  color: $primary-color;

  &:hover {
    text-decoration: underline;
    color: $primary-color;
  }
}

.consultation-info {
  margin-bottom: 20px;

  .info-text {
    margin: 0;
    word-break: break-all;
  }

  .title {
    font-weight: $medium-font-weight;
  }

  .info-more {
    display: block;
    padding: 4px 0;
    text-decoration: none;
    font-weight: $medium-font-weight;
    color: $primary-color;

    &:hover {
      text-decoration: underline;
      color: $primary-color;
    }
  }
}
