$accent-color: #3adcff;
$accent-muted-color: #0085ff;
$light-color: #ffffff;
$online-color: #69F0AE;
$warn-color: #BA1A1A;
$muted-color: rgba(255, 255, 255, 0.64);
$muted-disabled-color: rgba(255, 255, 255, 0.42);
$grey-color: #62688B;

// variables for doctors4UA
$header-color: #07054B;
$sidebar-color: #DAF6EC;
$text-color: #1B4761;
$primary-color: #09CF9E;

$regular-font-weight: 400;
$medium-font-weight: 500;
$bold-font-weight: 600;

$main-background: #1f275a;
$primary-background: #323c79;
$secondary-background: #293372;
$light-background: #303974;
$dark-background: #141d3c;
$accent-background: #4452a5;
$notification-background: rgba(0, 133, 255, 0.5);

$primary-vertical-gradient-background: linear-gradient(
  90deg,
  $light-background 1.98%,
  $dark-background 48.85%
);
$primary-horizontal-gradient-background: linear-gradient(
  179.97deg,
  $light-background 0%,
  $dark-background 60.32%
);
$accent-horizontal-gradient-background: linear-gradient(
  95.94deg,
  $accent-color 4.58%,
  #067dec 85.5%
);

$primary-text-color: #ffffff;
$accent-text-color: #3adcff;
$muted-text-color: #ffffffa3;

$primary-box-shadow: 0px 4px 40px #172039;

$primary-button-color: #ffffff;
$primary-button-background: $primary-color;
$primary-button-hover-background: darken($primary-color, 15%);
$primary-button-active-background: darken($primary-color, 15%);

$secondary-button-color: #ffffff;
$secondary-button-background: #09CF9E;
$secondary-button-hover-background: darken(#09CF9E, 15%);
$secondary-button-active-background: darken(#09CF9E, 15%);

$accent-button-color: $primary-color;
$accent-button-background: transparent;
$accent-button-hover-background: darken($primary-color, 15%);
$accent-button-active-background: darken($primary-color, 15%);
$accent-button-border: 2px solid $primary-color;

$danger-button-color: $warn-color;
$danger-button-background: transparent;
$danger-button-hover-background: #4C355C;
$danger-button-border: 2px solid $warn-color;
$danger-button-active-background: #3F2B55;

$primary-link-color: $primary-color;
$primary-link-hover-color: $primary-color;
$secondary-link-color: #ffffff;
$secondary-link-hover-color: #3adcff;

$border-radius: 12px;
$border: 1px solid rgba(255, 255, 255, 0.25);

