@import 'variables';
@import 'breakpoints';

.auth {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  &-header {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include sm {
      justify-content: space-between;
    }

    .logo {
      display: inline-block;

      img {
        width: 100%;
      }
    }

    a {
      display: none;

      @include sm {
        display: block;
      }
    }
  }

  &_content {
    display: flex;
    align-items: center;
    padding: 0 17px;
    flex-direction: column;

    @include sm {
      flex-direction: row;
      justify-content: center;
    }
  }

  &-layout {
    max-width: 475px;
    background-color: rgba(255, 255, 255, .7);
    border-radius: $border-radius;
    padding: 20px 25px;

    @include sm {
      padding: 20px 80px;
    }
  }

  &-link-mobile {
    margin-top: 30px;

    @include sm {
      display: none;
    }
  }

  &-body {
    .title {
      font-size: 24px;
      margin: 16px;
      font-weight: 400;
      color: var(--font-base);
    }

    .toggle-password {
      .mat-icon {
        color: #CFD8DC;
      }
    }

    input:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px var(--lihgt);
    }

    .auth_action {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .additional-action {
      text-align: center;
      margin: 16px 0 0;

      a {
        color: var(--font-base);
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .auth-info {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.1px;
      color: var(--font-base);
    }
  }

  &_decor {
    position: absolute;
    right: 40px;
    bottom: 0;
    display: none;

    @include xl {
      display: block;
    }
  }
}
