.mat-mdc-table {
  background-color: transparent !important;
  box-shadow: none !important;
}

.mat-mdc-header-cell,
.mat-mdc-cell {
  color: $light-color !important;
}

th.mat-mdc-header-cell,
td.mat-mdc-cell {
  border-bottom: $border !important;
}
