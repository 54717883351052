@import "breakpoints";

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primary-light);
  height: 4px;
}

.mat-mdc-tab-link {
  font-weight: 400;
  opacity: 1 !important;
  padding: 0 8px !important;
  min-width: 50px !important;
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: calc(100% - 2px);
    background-color: #ECEFF1;

    @include md {
      width: 100%;
    }
  }

  &:hover {
    text-decoration: none;
  }

  @include sm {
    padding: 0 15px !important;
    min-width: 70px !important;
  }

  @include md {
    padding: 0 25px !important;
    min-width: 160px !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-label-active {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-content {
    color: #191C1E !important;
  }
}

.mat-mdc-tab-header {
  border-bottom: 0;
}

.chat_tabs {
  height: 100%;
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-wrapper {
    height: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label {
    min-width: 70px;
  }

  .mat-mdc-tab-header {
    padding: 0 15px;

    .hide & {
      padding: 0;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    @include md {
      overflow: hidden;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-mdc-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled) {
    background-color: transparent !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none !important;
  }
}

.profile {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-active {
    color: var(--font-text-base);
    opacity: 1;
  }
}

.consultations_tabs {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-link-container {
    overflow-x: auto;
  }
}
