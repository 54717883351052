@import 'breakpoints';

fieldset {
  border: none;
  padding: 0;
}

textarea {
  font-family: inherit;
}

.phone {
  padding-bottom: 1.34375em;
  position: relative;

  & .iti {
    width: 100% !important;

    #phone {
      width: 100%;
      padding: 13px 10px 13px 90px !important;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.12);

      &:focus-visible {
        outline: #66B1F4;
      }
    }
  }

  &_profile {
    margin-top: 0 !important;

    & .iti {
      #phone {
        width: 100%;
        padding: 13px 10px 13px 90px !important;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.12);

        &:focus-visible {
          outline: #66B1F4 solid 2px;
          border: none;
        }
      }
    }

    & .ng-invalid {
      #phone {
        &:focus-visible {
          outline: var(--warn-color) solid 2px;
          border: none;
        }
      }
    }
  }

  &_number {
    & .mat-mdc-form-field-infix {
      width: inherit !important;
    }
  }

  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: var(--light);
  }

  &_placeholder {
    position: absolute;
    font-size: 10px;
    padding: 1px 6px;
    background-color: #fff;
    top: -5px;
    left: 6px;
    color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .search-container {
    padding: 15px;
  }

  #country-search-box {
    &:focus-visible {
      outline: none;
    }
  }

  .iti__country-list {
    box-shadow: none;
  }

  .iti__selected-flag {
    width: 80px !important;
  }
}

.communications_list {
  display: flex !important;
  justify-content: flex-start;

  @include md {
    flex-direction: row;
    justify-content: space-around;
  }

  .mat-mdc-list-item {
    display: flex !important;
    justify-content: flex-start;

    @include md {
      justify-content: center;
    }

    &-content {
      padding: 0;

    @include md {
      padding: 0 16px;
    }
    }
  }

  .mat-pseudo-checkbox-checked {
    background: var(--primary-light);
  }
}

.auth .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-start {
  max-width: 4px !important;
}
