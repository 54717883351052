@keyframes floating-visualization {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes dots-blink {
  50% {
    fill: transparent
  }
}
